import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
// import VModal from 'vue-js-modal'
// import Popover from 'vue-js-popover'
//  import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI);
// Vue.use(VModal)

// Vue.use(VModal, { dialog: true, dynamicDefault: { draggable: true, resizable: true,  height: 'auto'} })
// Vue.use(Popover)

Vue.config.productionTip = true


new Vue({
  router,
  // store,
  render: h => h(App)
}).$mount('#app')
