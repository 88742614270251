exports.showToast = (pointer, text, showCancelButton  = false, cb) => {
    pointer.$alert(text, '提示', {
        confirmButtonText: '确定',
        showCancelButton:showCancelButton,
        showClose:true,
        callback: (action) => {
            cb && cb(action)
            // this.$message({
            //   type: 'info',
            //   message: `action: ${ action }`
            // });
        }
    });
}