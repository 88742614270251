import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from "../pages/main/index.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//导航卫士： 这个顾名思义，就是在每个导航执行前执行
// router.beforeEach(function)
// router.afterEach(function)

// <!--      <router-link :to="{name:'url', param:{}, query:{} }">About</router-link>-->
// <!--      this.$route.query.queryId-->
// <!--      this.$route.params.queryId-->
// this.$router.push({name:'xxx',params:{key:value}})
// this.$router.push({name:'xxx',query:{key:value}})
// this.$router.go(-1)//跳转到上一次浏览的页面
// this.$router.replace('/menu')//指定跳转的地址
// this.$router.replace({name:'menuLink'})//指定跳转路由的名字下

export default router
