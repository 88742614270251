const { initCloud } = require('@wxcloud/cloud-sdk')
let c1 = null;

// todo:公众号相关开发
// https://developers.weixin.qq.com/miniprogram/dev/wxcloud/guide/web/minimal-example/html.html

// https://developers.weixin.qq.com/miniprogram/dev/wxcloud/guide/web/sdk.html
const initCloud2 = async () => {
    const cloud = initCloud()
// 声明新的 cloud 实例
//     未登录模式即无登录态的模式，在未登录模式中，不存在用户的登录态。未登录模式的场景有如：
//     单页模式：小程序/小游戏分享到朋友圈被打开时
//     Web 未登录模式：没有登录的 Web 环境中（见Web 端支持）
    //   未登录模式使用注意事项
//   出于安全考虑，云环境默认不支持未登录下访问，需首先在 ”云开发控制台 - 设置 - 全局设置“ 中开启（需开发者工具 1.03.2006042 或以上）
// 未登录模式必须搭配安全规则使用，若数据库、存储的权限设置为简易权限配置而不是安全规则配置，未登录用户将无法访问云资源
// 在未登录模式访问时，安全规则的 auth 变量将为空，可以以此判断未登录请求
//   https://developers.weixin.qq.com/miniprogram/dev/wxcloud/basis/identityless.html
     c1 = new cloud.Cloud({
        identityless: true,
        resourceAppid: 'wxb3681f4cf2751a7f',
        resourceEnv: 'icpedi-server-0g81q1naf58d388f',
    })
    await c1.init()

}

const fetchCall = (name, data, onSuccess, onFail) => {
    c1.callFunction({
        name:"icpedi",
        data:{
            type:name,
            data:data
        },
        success:(res) => {

            onSuccess && onSuccess(res.result.data)
        },
        fail:(error) => {

            onFail&&onFail(error)
        }
    })
}
const uploadFile = (type, file, onSuccess, onFail) => {
    let suffix = /\.\w+$/.exec(file.name)[0]; // 取文件后拓展名
    let cloudPath = (type === 'post' ? 'customPost/' : 'customAvatar/') + getDayTag() + "/" + Date.now() + '-' + Math.floor(Math.random() * 1000000) + suffix
    c1.uploadFile({
        cloudPath,
        file: file,//new File(file),
        success: res => {
            // get resource ID
            let fileID = res.fileID
            onSuccess && onSuccess(fileID)
        },
        fail: (error) => {
            // handle error
            onFail && onFail(error)
        }
    })
}
const getDayTag = () => {
    const myDate = new Date();
    myDate.getFullYear();    //获取完整的年份(4位,1970-????)
    myDate.getMonth();       //获取当前月份(0-11,0代表1月)
    myDate.getDate();        //获取当前日(1-31)
    const day = myDate.getDate()>=10?myDate.getDate():"0"+myDate.getDate();
    const month= myDate.getMonth()+1>=10?myDate.getMonth()+1:"0"+(myDate.getMonth()+1);
    myDate.toLocaleDateString();     //获取当前日期
    return myDate.getFullYear()+"-"+(month)+"-"+day;
}
module.exports = {
    uploadFile,
    initCloud2,
    fetchCall
}