<template src="./index.html" />
<style src="./index.css" scoped />

<script>
import {initCloud2} from "@/api/cloudAPI";
import {getCacheByKey, removeCacheByKey} from "@/cacheUtil";
import {showToast} from "@/utils";


export default {
  name:"Main",
  data() {
    return {
      activeIndex: 'home',
      selectedCategory:null,
      selectedDetail:null,
      user:null
    };
  },
  watch: {
    activeIndex:function()  {
      this.checkLoginStatus()
    },
    selectedCategory:function()  {
      this.checkLoginStatus()
    },
    selectedDetail:function()  {
      this.checkLoginStatus()
    },
  },
  async created() {
    this.checkLoginStatus()
    await initCloud2()
  },
  methods: {
    onLogin(){
      this.activeIndex = 'home'
      this.selectedCategory = null
      this.checkLoginStatus()
    },
    logout(){
      let that = this
      showToast(this, "确定登出吗?", true, (res) => {
        if(res === 'confirm'){
          that.user = null
          removeCacheByKey('user')
          this.activeIndex = 'home'
          this.selectedCategory = null
        }
      })
    },
    checkLoginStatus(){
      // if(user && user.number){
        //   //登录态
        // } else {
        //   //未登录
        // }
        this.user = getCacheByKey("user")

    },
    goBackFromDetail(){
      if(!this.selectedCategory){
        this.activeIndex = 'home'
      }
      this.selectedDetail = null
    },
    goToDetailFromCategory(e){
      this.activeIndex = null
      this.selectedDetail = e
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.activeIndex = key
      this.selectedCategory = null
      this.selectedDetail = null
    },
    goToDetailFromHome(e){
      this.activeIndex = "detail"
      this.selectedCategory = null
      this.selectedDetail = e
    },
    goBackFromCategory(){
      this.activeIndex = 'home'
      this.selectedCategory = null

    },
    onTapMore(e) {
      // this.activeIndex = "category"
      this.activeIndex = null
      this.selectedCategory = e.list[0].type
      console.log(e);
    }

  }
}

</script>
