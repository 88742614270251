


exports.getCacheByKey = (key) => {
    return localStorage.getItem(key)
}

exports.setCacheByKey = (key, value) => {
    return localStorage.setItem(key, value)
}

exports.removeCacheByKey = (key) => {
    return localStorage.removeItem(key)
}

exports.clearCache = () => {
    return localStorage.clear()
}

